



































































import { defineComponent, inject, onMounted, PropType, reactive, ref } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import * as MessageService from "@/api/messageService";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import Message from "./Message.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "Messages",
  components: { Message },
  props: {
    to: {
      type: Object as PropType<Pick<Learnlink.Profile.default, "firstName" | "uid">>,
      required: true
    }
  },
  setup(props) {
    const { isLoading, data: messages, refresh } = useAsyncData(() =>
      MessageService.list(userModule.state.userId, props.to.uid)
    );

    const vm = reactive({
      loading: false,
      text: "",
    });

    const wrapper = ref(null);
    const fetchUnreadMessages = inject("fetchUnreadMessages") as () => void;

    function scrollDown() {
      setTimeout(() => {
        if (wrapper.value !== null) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Object is possibly 'null'.
          wrapper.value.scrollTop = wrapper.value.scrollHeight;
        }
      }, 400);
    }

    onMounted(() => {
      scrollDown();

      setTimeout(() => {
        if (fetchUnreadMessages) fetchUnreadMessages();
      }, 1000);
    });

    async function send() {
      vm.loading = true;

      try {
        await MessageService.send({
          from: userModule.state.userId,
          text: vm.text,
          to: props.to.uid,
        });

        vm.text = "";

        await refresh();

        scrollDown();

        // TODO: Alert.
      }
      catch (e) {
        handleError(e);
      }

      vm.loading = false;
    }

    return {
      isLoading,
      messages,
      send,
      wrapper,
      vm
    };
  },
});
